define("bp-law-ember/templates/components/cta-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXTKs692",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"id\",\"cta\"],[11,\"class\",\"cta\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-lg-12 text-center\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"Contact Us Now & Set Up Your Free Consultation\"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"contactButton\"],[11,\"href\",\"\"],[9],[0,\"Let's Talk\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/components/cta-banner.hbs"
    }
  });

  _exports.default = _default;
});