define("bp-law-ember/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4cRf0n9O",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"bp-web-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"isLandingRoute\"]],\"wrapper\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLandingRoute\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"landing-header\"],false],[0,\" \\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\" \\n\\n\"],[4,\"unless\",[[23,[\"isLandingRoute\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"footer-wrapper\"],[9],[0,\"\\n        \"],[1,[21,\"main-footer\"],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/application.hbs"
    }
  });

  _exports.default = _default;
});