define("bp-law-ember/templates/components/mobile-landing-cta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aRnUk5+i",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"landing-cta no-desktop\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[7,\"b\"],[9],[0,\"Call Today!\"],[10],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    Free Consultations\\n  \"],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"tel:9548002896\"],[11,\"id\",\"phoneCircleIcon\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/imgs/phone-icon.png\"],[11,\"alt\",\"phone icon\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/components/mobile-landing-cta.hbs"
    }
  });

  _exports.default = _default;
});