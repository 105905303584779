define("bp-law-ember/components/mega-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function () {
      hoverMenu();
      adjustWidth();
      mobileMenu();
      responsiveHeader();
      bpWrapperWidth();
      Ember.$(window).resize(function () {
        responsiveHeader();
        bpWrapperWidth();
      });
    }
  });

  _exports.default = _default;

  function hoverMenu() {
    Ember.$(".has-submenu").find(".submenu-wrapper").fadeOut("fast");
    Ember.$(".has-submenu").on({
      mouseenter: function () {
        Ember.$(this).find(".submenu-wrapper").stop().fadeIn("fast");
      },
      mouseleave: function () {
        Ember.$(this).find(".submenu-wrapper").stop().fadeOut("fast");
      }
    });
  }

  function adjustWidth() {
    setWidth();
    Ember.$(window).resize(function () {
      setWidth();
    });
  }

  function setWidth() {
    var mega_submenu = Ember.$(".tgl-megadrop .has-submenu .submenu-wrapper");
    var menu_width = Ember.$(".tgl-megadrop").width();
    var left_margin = Ember.$(".tgl-megadrop").offset().left;

    if (mega_submenu.hasClass("mega-full-width")) {
      mega_submenu.css({
        left: left_margin + "px",
        "max-width": menu_width + "px"
      });
    } else {
      mega_submenu.css({
        "max-width": menu_width + "px"
      });
    }
  }

  function responsiveHeader() {
    var window_width = Ember.$(window).width();
    var mainMenu = Ember.$("#mainMenu");
    var mobileMenu = Ember.$("#mobileMenu");

    if (window_width < 1600) {
      mainMenu.addClass("is-disabled");
      mainMenu.removeClass("is-active");
      mobileMenu.addClass("is-active");
      mobileMenu.removeClass("is-disabled");
    } else {
      mainMenu.removeClass("is-disabled");
      mainMenu.addClass("is-active");
      mobileMenu.addClass("is-disabled");
      mobileMenu.removeClass("is-active");
    }
  }

  function bpWrapperWidth() {
    var bpWrapper = Ember.$("#bp-web-wrapper");
    var wrapperWidth = Ember.$("#bp-web-wrapper").width();
    bpWrapper.css({
      width: wrapperWidth + "px"
    });
  }

  function mobileMenu() {
    var sideMenu = Ember.$("#sideMenu");
    var sideMenuLi = Ember.$("#sideMenu li a");
    var mobileMenuButton = Ember.$(".mobile-menu-button");
    var bpWrapper = Ember.$("#bp-web-wrapper");
    mobileMenuButton.on({
      click: function () {
        event.preventDefault();

        if (sideMenu.hasClass("is-disabled")) {
          sideMenu.removeClass("is-disabled");
          sideMenu.addClass("is-active");
          bpWrapper.animate({
            marginLeft: "-300px"
          }, 600, function () {// Animation complete.
          });
          sideMenu.animate({
            marginRight: "0"
          }, 600, function () {// Animation complete.
          });
          return false;
        } else if (sideMenu.hasClass("is-active")) {
          sideMenu.removeClass("is-active");
          sideMenu.addClass("is-disabled");
          bpWrapper.animate({
            marginLeft: "0px"
          }, 600, function () {// Animation complete.
          });
          sideMenu.animate({
            marginRight: "-300px"
          }, 600, function () {// Animation complete.
          });
          return false;
        }
      }
    });
    sideMenuLi.on({
      click: function () {
        sideMenu.removeClass("is-active");
        sideMenu.addClass("is-disabled");
        bpWrapper.animate({
          marginLeft: "0px"
        }, 600, function () {// Animation complete.
        });
        sideMenu.animate({
          marginRight: "-300px"
        }, 600, function () {// Animation complete.
        });
      }
    });
  }
});