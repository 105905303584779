define("bp-law-ember/templates/components/intro-video-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NMmcFqWG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"video-intro-position\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/imgs/watch-intro-text.png\"],[11,\"alt\",\"tab text\"],[9],[10],[0,\" \"],[1,[27,\"ember-youtube\",null,[[\"ytid\",\"width\",\"height\",\"playing\",\"paused\",\"ended\",\"delegate\",\"delegate-as\",\"autoplay\"],[\"_Gl-lVhWusw\",\"360\",\"270\",\"videoPlaying\",\"videoPaused\",\"videoEnded\",[23,[\"controller\"]],\"videoPlayer\",false]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/components/intro-video-tab.hbs"
    }
  });

  _exports.default = _default;
});