define("bp-law-ember/components/cta-circles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["circle-cta-wrapper"]
  });

  _exports.default = _default;
});