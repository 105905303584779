define("bp-law-ember/transitions/crass-fade", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = crossFade;

  function crossFade(opts) {
    opts = opts || {};
    (0, _liquidFire.stop)(this.oldElement);
    return _liquidFire.Promise.all([(0, _liquidFire.animate)(this.oldElement, {
      opacity: 0
    }, opts), (0, _liquidFire.animate)(this.newElement, {
      opacity: [opts.maxOpacity || 1, 0]
    }, opts)]);
  }
});