define("bp-law-ember/templates/components/team-tabs-spanish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kma+CXg6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"spanish.about.mario\"],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"team-member-tab\"],[11,\"id\",\"marioActive\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"team-member-tab-icon\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/imgs/mario.png\"],[11,\"alt\",\"Mario Perez Icon Image\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"Mario Perez\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"spanish.about.randell\"],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"team-member-tab\"],[11,\"id\",\"randellActive\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"team-member-tab-icon\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/imgs/randall.png\"],[11,\"alt\",\"Randell Baisden Icon Image\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"Randell Baisden\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"spanish.about.alexander\"],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"team-member-tab\"],[11,\"id\",\"alexActive\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"team-member-tab-icon\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/imgs/alex.png\"],[11,\"alt\",\"Alex Baisden Icon Image\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"Alexander Baisden\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/components/team-tabs-spanish.hbs"
    }
  });

  _exports.default = _default;
});