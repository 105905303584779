define("bp-law-ember/controllers/spanish/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dots: true,
    infinite: true,
    speed: 1500,
    fade: true,
    cssEase: "linear"
  });

  _exports.default = _default;
});