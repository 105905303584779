define("bp-law-ember/templates/components/cta-circles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "me3rsdsB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"circle-cta\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"tel:9547490500\"],[11,\"id\",\"phoneCta\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/imgs/phone-icon.png\"],[11,\"alt\",\"phone icon\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"contact\"],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"id\",\"emailCta\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/imgs/contact-icon.png\"],[11,\"alt\",\"contact icon\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/components/cta-circles.hbs"
    }
  });

  _exports.default = _default;
});