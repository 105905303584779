define("bp-law-ember/templates/components/landing-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GeIP2QXi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nav\"],[11,\"class\",\"navbar navbar-default navbar-fixed-top\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"navbar-header page-scroll\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"navbar-brand page-scroll\"],[11,\"href\",\"#page-top\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"src\",\"imgs/logo.png\"],[11,\"alt\",\"logo\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"collapse navbar-collapse\"],[11,\"id\",\"bs-example-navbar-collapse-1\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"right col-md-4\"],[9],[0,\"\\n        \"],[7,\"ul\"],[11,\"class\",\"nav navbar-nav navbar-right\"],[9],[0,\"\\n          \"],[7,\"li\"],[11,\"class\",\"hidden\"],[9],[0,\"\\n            \"],[7,\"a\"],[11,\"href\",\"#page-top\"],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"li\"],[11,\"class\",\"phone-cta\"],[9],[0,\"\\n            \"],[7,\"p\"],[9],[0,\"\\n              \"],[7,\"span\"],[9],[0,\"Call to Schedule Your FREE Video Conference!\"],[10],[0,\"\\n              \"],[7,\"br\"],[9],[10],[0,\"\\n              \"],[7,\"i\"],[11,\"class\",\"fa fa-phone\"],[9],[10],[0,\"\\n              \"],[7,\"span\"],[11,\"id\",\"phoneNumber\"],[9],[0,\"(954) 800-2896\"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/components/landing-header.hbs"
    }
  });

  _exports.default = _default;
});