define("bp-law-ember/templates/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGFO8daa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"contactPage\"],[9],[0,\"\\n  \"],[1,[21,\"nav-bar\"],false],[0,\" \\n  \"],[1,[27,\"google-map\",null,[[\"latitude\",\"longitude\",\"gopt_styles\",\"map\"],[\"26.1272867\",\"-80.2640725\",[23,[\"view\",\"mapStyles\"]],[23,[\"view\",\"googleMap\"]]]]],false],[0,\" \\n  \"],[1,[27,\"contact-section\",null,[[\"id\"],[\"page-content\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/contact.hbs"
    }
  });

  _exports.default = _default;
});