define("bp-law-ember/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import config from './config/environment';
  var Router = Ember.Router.extend({
    location: "auto"
  }); // Router.map(function () {
  //   this.route("home", {
  //     path: "/",
  //   });
  //   this.route("landing-page");
  //   this.route("about");
  //   this.route("defense-base-act");
  // });

  var _default = Router.map(function () {
    this.route("practice-areas", function () {
      this.route("non-appropriated");
      this.route("cruise-ship");
      this.route("outer-lands");
      this.route("jones-act");
      this.route("maritime-law");
      this.route("personal-injury");
      this.route("longshore-act");
      this.route("defense-base-acts");
      this.route("workers-comp");
    });
    this.route("mega-menu", function () {
      this.route("mega-menu.countries");
    });
    this.route("about-dba", function () {
      this.route("what-is-dba");
      this.route("dba-backgrounds");
      this.route("dba-claims");
      this.route("dba-comp");
      this.route("dba-injuries");
      this.route("dba-lawyers");
      this.route("what-to-expect");
      this.route("why-dba-attorney");
    });
    this.route("about", function () {
      this.route("mario");
      this.route("randell");
      this.route("joshua");
      this.route("alexander");
    });
    this.route("countries", function () {
      this.route("japan");
      this.route("kuwait");
      this.route("afghanistan");
      this.route("iraq");
      this.route("guam");
      this.route("qatar");
    });
    this.route("landing-page");
    this.route("home", {
      path: '/'
    });
    this.route("contact");
  });

  _exports.default = _default;
});