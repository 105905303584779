define("bp-law-ember/components/landing-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      Ember.$(window).scroll(function () {
        if (Ember.$(window).scrollTop() > 100) {
          Ember.$(".navbar-default").css("background", "#252F35");
          Ember.$(".navbar-default .navbar-brand img").css("height", "30px");
        } else {
          Ember.$(".navbar-default").css("background", "rgba(0,0,0,0)");
          Ember.$(".navbar-default .navbar-brand img").css("height", "44px");
        }
      });
    }

  });

  _exports.default = _default;
});