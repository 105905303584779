define("bp-law-ember/components/google-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      var container = this.$(".map-canvas")[0];
      var options = {
        center: new window.google.maps.LatLng(this.get("latitude"), this.get("longitude")),
        zoom: 15
      };
      new window.google.maps.Map(container, options);
    }

  });

  _exports.default = _default;
});