define("bp-law-ember/templates/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HDMPxxIG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nav\"],[11,\"id\",\"navBar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"logo\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"home\"],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[11,\"src\",\"/imgs/logo.png\"],[11,\"alt\",\"logo image\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[1,[21,\"mega-drop\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bp-law-ember/templates/components/nav-bar.hbs"
    }
  });

  _exports.default = _default;
});