define("bp-law-ember/components/intro-video-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["video-intro-wrapper"],
    _tabState: "closed",
    // Closed, Open, Playing
    _mouseInside: false,

    didInsertElement() {
      var self = this;
      Ember.$(".video-intro-position").on({
        mouseenter: function mouseenter() {
          self._mouseInside = true;
          Ember.run.later(() => {
            if (self._mouseInside === "true" || self._tabState === "closed") {
              self.opening();
            }
          }, 200);
        },
        mouseleave: function mouseleave() {
          self._mouseInside = false;

          if (self._tabState === "open") {
            self.close();
          } else if (self._tabState === "paused") {
            self.close();
          } // else if (self._tabState === "playing") {
          // }

        },
        click: function click() {
          self.close();
        }
      });
    },

    opening: function () {
      this._tabState = "opening";
      Ember.$(".video-intro-position").animate({
        marginRight: "-60px"
      }, 1000, function () {// Animation complete.
      });
      Ember.run.later(() => {
        if (this._tabState === "playing") {
          this.play();
        } else if (!this._mouseInside) {
          this.close();
        } else {
          this.open();
        }
      }, 1500);
    },
    open: function () {
      this._tabState = "open";
    },
    close: function () {
      this._tabState = "closing";
      Ember.run.later(() => {
        this._tabState = "closed";
      }, 1200);
      Ember.$(".video-intro-position").animate({
        marginRight: "-440px"
      }, 1000, function () {// Animation complete.
      });
    },
    play: function () {
      this._tabState = "playing";
    },
    ended: function () {
      this._tabState = "open";

      if (!this._mouseInside) {
        Ember.run.later(() => {
          if (this._tabState === "open") {
            this.close();
          }
        }, 1000);
      }
    },
    pause: function () {
      this._tabState = "paused";
    },
    actions: {
      videoPlaying: function () {
        this.play();
      },
      videoEnded: function () {
        this.ended();
      },
      videoPaused: function () {
        this.pause();
      }
    }
  });

  _exports.default = _default;
});