define("bp-law-ember/views/google-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const mapStyles = [{
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [{
      color: "#444444"
    }]
  }, {
    featureType: "landscape",
    elementType: "all",
    stylers: [{
      color: "#f2f2f2"
    }]
  }, {
    featureType: "landscape.natural.landcover",
    elementType: "geometry.fill",
    stylers: [{
      saturation: "37"
    }, {
      lightness: "75"
    }, {
      gamma: "1.27"
    }, {
      weight: "8.40"
    }]
  }, {
    featureType: "poi",
    elementType: "all",
    stylers: [{
      visibility: "off"
    }]
  }, {
    featureType: "road",
    elementType: "all",
    stylers: [{
      saturation: -100
    }, {
      lightness: 45
    }]
  }, {
    featureType: "road.highway",
    elementType: "all",
    stylers: [{
      visibility: "simplified"
    }]
  }, {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{
      color: "#E7D198"
    }]
  }, {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [{
      visibility: "off"
    }]
  }, {
    featureType: "transit",
    elementType: "all",
    stylers: [{
      visibility: "off"
    }]
  }, {
    featureType: "water",
    elementType: "all",
    stylers: [{
      color: "#7da0b5"
    }, {
      visibility: "on"
    }]
  }];

  var _default = Ember.View.extend({
    mapStyles: mapStyles
  });

  _exports.default = _default;
});