define("bp-law-ember/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentPathChanged: Ember.observer("currentPath", function () {
      window.scrollTo(0, 0);
    }),
    isLandingRoute: Ember.computed.equal("currentRouteName", "landing-page")
  });

  _exports.default = _default;
});