define("bp-law-ember/components/contact-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      sendMandrillEmail: function () {
        var leadName = Ember.$("#name").val();
        var leadEmail = Ember.$("#email").val();
        var leadSubject = Ember.$("#subject").val();
        var leadMessage = Ember.$("#message").val();
        var leadHtml = "Name:" + leadName + "<br/>" + "Email:" + leadEmail + "<br/>" + "Subject:" + leadSubject + "<br/>" + "Message:" + leadMessage;
        let emailObject = {
          message: {
            html: leadHtml,
            subject: leadSubject,
            from_email: leadEmail,
            from_name: leadName,
            to: [{
              email: "rb@baisdenperezlaw.com",
              name: "Randell Baisden",
              type: "to"
            }, {
              email: "mario@baisdenperezlaw.com",
              name: "Mario Perez",
              type: "to"
            }, {
              email: "michele@baisdenperezlaw.com",
              name: "Michele",
              type: "to"
            }, {
              email: "account@tgld.co",
              name: "Carlos",
              type: "to"
            }]
          }
        };
        this.get("mandrill").send(emailObject).then(function (response) {
          var status = response[0].status;

          if (status === "rejected") {
            Ember.$(".form-control").val("");
            Ember.$(".contactButton").css("border-color", "#FF0000");
            setTimeout(function () {
              Ember.$(".contactButton").css("border-color", "#F0DCA4");
            }, 2000);
          } else if (status === "sent") {
            Ember.$(".contactButton").css("border-color", "#54BB5B").html("Thank You!");
            setTimeout(function () {
              Ember.$(".contactButton").css("border-color", "#F0DCA4").html("Let's Talk");
            }, 2000);
            Ember.$(".form-control").val("");
          }
        });
      }
    }
  });

  _exports.default = _default;
});